import * as React from 'react'

import { ProductContext } from '../context/ProductContext'

type ProductSpecificationProps = {
    name: string | null;
    html: string | null;
}

const ProductSpecification = ({ name, html }: ProductSpecificationProps) => {
    const [open, setOpen] = React.useState(false)

    return <div key={name || ""}>
        <h3>
            <a href="javascript:void(0)" onClick={(e) => {
                e.preventDefault()
                setOpen(!open)
            }} className={open ? "table-toggle active" : "table-toggle"}>
                <span>{name}</span>
            </a>
        </h3>
        {
            open && <div id="specification-table" style={{ display: "block" }} className="specification-block product-table" dangerouslySetInnerHTML={{ __html: html || "" }}></div>
        }
    </div>
}

const ProductSpecifications = () => {
    const { productSpecifications } = React.useContext(ProductContext)

    return <>
        {productSpecifications.map(p => {
            return <ProductSpecification key={p.name || ""} name={p.name} html={p.descriptionHtml} />
        })}
    </>
}

export default ProductSpecifications
