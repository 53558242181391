import * as React from 'react';

import { ApiClient } from './client/ApiClient'
import { ProductContext, EmptyResponse } from './context/ProductContext'

import ConfigurationPropsType from './types/ConfigurationProps'
import { Product } from './types/ProductType'

import ProductHeading from './components/ProductHeading'
import ProductDescription from './components/ProductDescription'
import ProductSpecifications from './components/ProductSpecifications'
import PartGroups from './components/PartGroup'

export const App = ({ url, companyId, language, showListPrice, showPartNumber }: ConfigurationPropsType) => {
  const [baseUrl, setBaseUrl] = React.useState<string>("");
  const [product, setProduct] = React.useState<Product>(EmptyResponse.product);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!baseUrl) { return }
    var style = document.createElement('link');
    style.href = `${baseUrl}/${'assets/product-template/product.min.css'}`
    style.rel = "stylesheet";
    document.head.appendChild(style);
  }, [baseUrl])

  React.useEffect(() => {
    const parsedUrl = new URL(url || "")
    const apiClient = new ApiClient(parsedUrl.origin)
    setBaseUrl(parsedUrl.origin || "")

    apiClient.getProduct(parsedUrl.pathname, companyId || "", language || "", showListPrice, showPartNumber)
      .then(response => {
        setProduct(response.product)
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <p>Loading product</p>
  }

  if (error) {
    return <p>There was a problem loading the product.</p>
  }

  return <ProductContext.Provider value={product}>
    <ProductHeading baseUrl={baseUrl} />
    <ProductDescription />
    <section className="product-tables">
      <ProductSpecifications />
      <h2 id="product-parts" className="page-content-section">Parts</h2>
      <PartGroups name="Parts" parts={product.partGroups} showPartNumber={showPartNumber} />
      <h2 id="product-parts" className="page-content-section">Options</h2>
      <PartGroups name="Options" parts={product.optionGroups} showPartNumber={showPartNumber} />
    </section>
  </ProductContext.Provider>
}
