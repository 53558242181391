import * as React from 'react'

import { ProductContext } from '../context/ProductContext'

const ProductDescription = () => {
    const { productDescriptionHtml } = React.useContext(ProductContext)

    return <div dangerouslySetInnerHTML={{ __html: productDescriptionHtml || "" }} />
}

export default ProductDescription
