import * as React from "react";
import { ProductApiResponse } from "../types/ProductType";

const EmptyResponse = {
  product: {
    approvals: [],
    contentHtml: null,
    introductionHtml: null,
    name: null,
    optionGroups: [],
    partGroups: [],
    productDescriptionHtml: null,
    productImages: [],
    productSpecifications: [],
    proposition65Warning: false,
  },
  css: "",
} as ProductApiResponse;

const EmptyProduct = EmptyResponse.product;

const ProductContext = React.createContext(EmptyProduct);

export { ProductContext, EmptyResponse };
