/* tslint:disable */

import { ProductApiResponse } from "../types/ProductType";

/* eslint-disable */
export class ApiClient {
  private http: {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
  };
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    this.http = http ? http : <any>window;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  /**
   * @return Success
   */
  getProduct(
    productUrl: string,
    dealerId: string,
    language: string = "en",
    showListPrice: boolean,
    showPartNumber: boolean
  ): Promise<ProductApiResponse> {
    let url_ = `${this.baseUrl}/umbraco/api/dealer/getproduct?url=${productUrl}&dealerId=${dealerId}&language=${language}&showListPrice=${showListPrice}&showPartNumber=${showPartNumber}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "text/plain",
      },
    };

    return this.http.fetch(url_, options_).then((_response: Response) => {
      if (_response.status === 200) {
        return _response.json().then((data) => {
          return data as ProductApiResponse;
        });
      } else {
        console.error("Non-200 response from API");
        throw "Could not get product";
      }
    });
  }
}
