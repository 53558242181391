import * as React from 'react'

import { PartGroup, IndividualPart } from '../types/ProductType'

type PartGroupProps = {
    name: string;
    parts: IndividualPart[];
    filter: string | undefined;
    showPartNumber: boolean;
}

const PartGroup = ({ name, parts, filter, showPartNumber }: PartGroupProps) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [filteredParts, setFilteredParts] = React.useState(parts)

    React.useEffect(() => {
        if (!filter) {
            setFilteredParts(parts)
        } else {
            var filtered = parts.filter(p => {
                const lower = [p.description, p.listPrice, p.partNumber].map(p => p.toLowerCase())
                return lower.filter(p => p.includes(filter.toLowerCase())).length > 0
            })
            setFilteredParts(filtered)
            setIsOpen(true)
        }
    }, [filter])

    return <>
        <h3>
            <a href="javascript:void(0);" onClick={() => {
                setIsOpen(!isOpen)
            }} className={isOpen ? "table-toggle active" : "table-toggle"} aria-controls="part" aria-expanded="false">
                <span>{name}</span>
            </a>
        </h3>

        {isOpen &&
            <table style={{ display: "block" }} className="product-table responsive-table parts-table">
                <colgroup>
                    {
                        showPartNumber && <col style={{ width: '90px' }} />
                    }
                    <col />
                    <col style={{ width: '150px' }} />
                </colgroup>
                <thead>
                    <tr>
                        {
                            showPartNumber && <th>Part #</th>
                        }
                        <th>Product Description</th>
                        <th>List Price</th>
                    </tr>
                </thead>
                <tbody>

                    {filteredParts.map(p => {
                        return <tr key={p.partNumber}>
                            {
                                showPartNumber && <td data-label="Part #">
                                    <a>{p.partNumber}</a>
                                </td>
                            }
                            <td data-label="Description">
                                {p.description}
                            </td>
                            <td data-label="List Price">
                                {p.listPrice}
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        }
    </>
}

type PartGroupsProps = {
    parts: PartGroup[];
    name: string;
    showPartNumber: boolean;
}

const PartGroups = ({ parts, name, showPartNumber }: PartGroupsProps) => {
    const [filter, setFilter] = React.useState("")

    if (parts.length === 0) {
        return <p className="product-not-found">No {name} found for this product</p>
    }

    return <>
        <form className="product-search">
            <input type="search" placeholder="Search by Keyword" className="part-search-input" value={filter}
                onChange={(e) => {
                    const { target: { value } } = e
                    setFilter(value)
                }} />
        </form>

        {parts.map(p => {
            return <PartGroup name={p.name} parts={p.parts} filter={filter} showPartNumber={showPartNumber} />
        })}
    </>
}

export default PartGroups
