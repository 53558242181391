import * as React from "react";

import { ProductContext } from "../context/ProductContext";
import { ProductImage } from "../types/ProductType";

type ProductHeadingProps = {
  baseUrl: string;
};

const ProductHeading = ({ baseUrl }: ProductHeadingProps) => {
  const {
    name,
    introductionHtml,
    proposition65Warning,
    approvals,
    productImages,
  } = React.useContext(ProductContext);
  const [selectedImage, setSelectedImage] = React.useState({} as ProductImage);
  let urlName = "";
  if (name) {
    urlName = encodeURIComponent(name)
  };
  React.useEffect(() => {
    setSelectedImage(productImages[0] || ({} as ProductImage));
  }, [productImages]);

  return (
    <div className="col-section col-no-margin">
      <div
        id="product-intro"
        className="col-xs-12 col-sm-6 col-md-5 page-content-section"
      >
        <h1>{name}</h1>

        <div dangerouslySetInnerHTML={{ __html: introductionHtml || "" }} />

        <div id="product-approvals">
          <h2>Approvals</h2>
          <p>
            Some approval documents are available to view by clicking the icons
            below.
          </p>

          {approvals.map((p) => {
            if (p.link) {
              return (
                <a
                  key={p.imageUrl}
                  href={`${baseUrl}${p.link}`}
                  target="_blank"
                >
                  <img
                    src={`${baseUrl}${p.imageUrl}?height=50`}
                    alt={p.imageName || ""}
                    height="50"
                  />
                </a>
              );
            } else {
              return (
                <img
                  key={p.imageUrl}
                  src={`${baseUrl}${p.imageUrl}?height=50`}
                  alt={p.imageName || ""}
                  height="50"
                />
              );
            }
          })}

          {proposition65Warning && (
            <div id="product-warning">
              <img
                src={`${baseUrl}/assets/images/exclamation-triangle.svg`}
                alt=""
                width="25"
              />
              <p>
                WARNING: Cancer and Reproductive Harm California Proposition 65
              </p>
            </div>
          )}

          <a href={`/contact?product=${urlName}`} className="btn">
            Request Information
          </a>
        </div>
      </div>

      <div className="col-xs-12 col-sm-6 col-md-7">
        <div id="product-main-image">
          {selectedImage ? (
            <img
              src={`${baseUrl}${selectedImage.href}?width=500&height=500&mode=max`}
              alt={selectedImage.alt || ""}
            />
          ) : (
            <img
              src={`${baseUrl}/assets/images/logo.png`}
              style={{ opacity: ".5" }}
              width="213"
              height="50"
            />
          )}
        </div>

        {/* @if (Model.Product360 != null)
        {
                <div id="product-360-image">
                    @Html.Magic360(Model.Product360)
            </div>
            } */}

        <ul id="product-thumbnails">
          {productImages.map((o) => {
            return (
              <li key={o.href || ""}>
                <button
                  type="button"
                  data-view="thumbnail"
                  onClick={() => {
                    setSelectedImage(o);
                  }}
                  style={{
                    backgroundImage: `url('${baseUrl}${o.href}?height=100')`,
                  }}
                ></button>
                <a
                  className="hd-view"
                  target="_blank"
                  href={`${baseUrl}${o.href}`}
                >
                  HD View
                </a>
              </li>
            );
          })}

          {/* @if (Model.Product360 != null)
            {
                    <li>
                        <button type="button" data-view="product360" style="background-image: url('/assets/images/product-details-360-icon.png')"></button>
                        <a class="hd-view">360 View</a>
                    </li>
                } */}

          {/* @if (Model.Videos != null)
            {
                    foreach(var productVideo in Model.Videos)
                {
                    if (productVideo == null) { continue; }

                    var videoUrl = productVideo?.MediaLibraryVideo?.Url() ?? productVideo?.VideoUrl?.Url ?? "";
                    if (string.IsNullOrEmpty(videoUrl)) { continue; }

                    <li>
                    <div class="video-overlay">
                        <a href="javascript:void(0);" onmousedown="openModal('video-modal-@productVideo.Key');">
                            <span class="fas fa-play-circle play-btn"></span>
                                @if (productVideo.PlaceholderImage != null)
                                {
                                <img class="placeholder" src="@productVideo.PlaceholderImage.Url()?height=100" />
                            }
                                else
                                {
                                <img class="placeholder" src="/assets/images/logo.png" style="opacity: .5" width="213" height="50" />
                            }
                        </a>
                    </div>
                    <a class="hd-view" href="javascript:void(0);" onmousedown="openModal('video-modal-@productVideo.Key');">Video</a>
                </li>
                }
            } */}
        </ul>
      </div>
    </div>
  );
};

export default ProductHeading;
