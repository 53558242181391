import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './app/App';
import 'whatwg-fetch';// polyfill window.fetch

let node = document.getElementById('rl-product') as HTMLElement;
let url = node.getAttribute("data-url");
let companyId = node.getAttribute("data-company-id");
let language = node.getAttribute("data-language");
let showPartNumber = node.getAttribute("data-show-part-number");
let showListPrice = node.getAttribute("data-show-list-price");

if (showPartNumber) {
  showPartNumber = showPartNumber.toLocaleLowerCase();
}

if (showListPrice) {
  showListPrice = showListPrice.toLocaleLowerCase();
}

ReactDOM.render(
  <App url={url}
    companyId={companyId}
    language={language}
    showListPrice={showListPrice === 'true' || showListPrice === '1'}
    showPartNumber={showPartNumber === 'true' || showPartNumber === '1'} />,
  node
);